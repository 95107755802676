import React from "react";
import { Col, Row } from "react-bootstrap";
import { Field, Input, Toggle, Label, Message } from "@zendeskgarden/react-forms";
//@ts-ignore
import FilestackReact from "filestack-react";
import FileStackService from "../../services/filestack.service";
import ReactChipInput from '../../components/opportunity/chip-input';
import TranslationService, { t } from "../../translations/translation.service";
import service from "../../services/api.service";
import DictionaryService from "../../services/dictionary.service";
import { UserData } from "../../model/user.model";
import { Dropdown, Item, Menu, Select, Field as DropdownField } from "@zendeskgarden/react-dropdowns";
import PillChip from '../../components/opportunity/pill';
import { DictionaryDomain } from "../../model/domain.model";
import Loading from "../../components/loading";
import { AlertService } from "../../services/alert.service";
import styles from './index.module.scss';
import UserService from "../../services/user.service";

class ProfessionalByRh extends React.Component <any>{

  state: Readonly<{
    policyString: string,
    signatureString:  string,
    files: any[],
    fileName: string,
    arrayInterests: any,
    arraySkill: string[],
    name: string,
    email: string,
    lastName: string,
    telefone: string,
    linkedinUrl: string,
    isPcd: boolean,
    notification: boolean,
    userInterests: any[],
    isLoading: boolean,
    alertMessage: boolean,
    overlay: boolean,
    policyAndSignature: string,
    europeanUnionCitizenship: boolean,
  }> = {
    policyString: '',
    signatureString: '',
    files: [],
    fileName: '',
    arrayInterests: [],
    arraySkill: [],
    name: '',
    email: '',
    lastName: '',
    telefone: '',
    linkedinUrl: '',
    isPcd: false,
    notification: false,
    userInterests: [],
    isLoading: false,
    alertMessage: false,
    overlay: false,
    policyAndSignature: '',
    europeanUnionCitizenship: false
  };

  constructor(props: any) {
    super(props);
    this.policeAndSignatureString = this.policeAndSignatureString.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.checkFields = this.checkFields.bind(this);
  }

  addChip = (value: string, list: string[], state: string) => {
    const listType = list.slice();
    listType.push(value);
    this.setState({ [state]: listType });
  };

  removeChip = (index: number, list: string[], state: string) => {
    const listType = list.slice();
    listType.splice(index, 1);
    this.setState({ [state]: listType });
  };

  pushUserInterest = (list: any[]) => {
    const infoDetail = list.map((item: string) => {
      return (
        {
          detalhe: {
            nomeCaption: item,
            subclassif: "attitudes",
            classif: "euquero",
            nome: item,
          }
        } 
      )
    })
    return infoDetail;
  }

  pushUserSkills = (list: any[]) => {
    const lista = list.map((item: string) => {
      return (
        {
          nomeCaption: item,
          subclassif: "skills",
          classif: "digomais",
          nome: item
        }
      )
    });
    return lista;
  }

  handleChange = (event: any): void => {
    const name = event.target.name;
    const isToggle = Object.keys(event.target).find((key) => key === "checked");
    if (isToggle) {
      this.setState({ [name]: event.target.checked });
    } else {
      this.setState({[name]: event.target.value});
    }
  }

  async policeAndSignatureString(): Promise<void> {
    try {
      const policyAndSignature = await FileStackService.getPoliceAndSignature();
      this.setState({
        policyString: policyAndSignature.data.policy,
        signatureString: policyAndSignature.data.signature
      });
    } catch(err: any) {
      console.log("ERROR: ", err);
    }
  }

  checkFields = () => {
    if (
      this.state.name.length < 3 ||
      this.state.lastName.length < 3 ||
      this.state.email.length < 3 ||
      this.state.arraySkill.length < 1
    ) {
      AlertService.warn("Os campos nome, sobrenome, e-mail e competências são obrigatórios");
      if (this.state.arraySkill.length < 1) {
        this.setState({ overlay: true });
      }
      this.setState({ alertMessage: true, isLoading: false })
      return true;
    }
    if (!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      AlertService.warn("Por favor preencha com um e-mail válido");
      this.setState({ alertMessage: true, isLoading: false });
      return true;
    }
    return false;
  }

  onSubmit = async () => {
    const editUser = this.props.match.path.endsWith("edit");
    this.setState({isLoading: true});
    let contato: any = {};
    let lista = [];
    lista.push({
      locale: "pt-BR",
      contato: this.state.telefone,
      tipo: "Preferencial",
    });
    contato.lista = lista;
    const data: any = {
      user: {
        perfilUsuario: {
          mosaico: {
            escolhidas: this.pushUserInterest(this.state.userInterests)
          },
          digomais: {
            lista: this.pushUserSkills(this.state.arraySkill)
          }
        },
        firstName: this.state.name,
        lastName: this.state.lastName,
        email: this.state.email,
        dadosPessoais: {
          pcdUser: {
            isUserPCD: this.state.isPcd,
          },
          preferredLanguage: TranslationService.getCurrentLanguage(),
        },
        contato: contato,
        attachments: this.state.files,
        notificationsSettings: {
          isActive: this.state.notification,
          channels: [
            {
              isActive: this.state.notification,
              name: "E-mail"
            },
            {
              isActive: this.state.notification,
              name: "Telefone"
            }
          ]
        },
        registerRh: true,
        linkedinUrl: this.state.linkedinUrl,
        policyAndSignature: this.state.policyAndSignature,
        europeanUnionCitizenship: this.state.europeanUnionCitizenship
      }
    }

    if (this.checkFields()) {
      return
    }
    try {
      if (editUser) {
        data.user._id = this.props.match.params.id;
        const user = data.user;
        const response = await UserService.updatePreRegister(user);
        if (response.status === 200) {
          AlertService.success("Profissional atualizado com sucesso!");
          this.setState({ isLoading: false });
        }
        window.open(`https://elumini.enter.jobs/professional/${user._id}`, '_blank', 'noopener,noreferrer');
      } else {
        const response = await service.post("auth/signup", data);
        if (response.status === 200) {
          AlertService.success(t("Profissional cadastrado com sucesso!"));
          window.open(`http://localhost:3000/professional/${response.data.user.id}`, '_blank', 'noopener,noreferrer');
          this.setState({   
            files: [],
            fileName: '',
            arraySkill: [],
            name: '',
            email: '',
            lastName: '',
            telefone: '',
            linkedinUrl: '',
            isPcd: false,
            notification: false,
            userInterests: [],
            isLoading: false,
            overlay: false,
            europeanUnionCitizenship: false
          });
        }
      }
    } catch(err: any) {
      console.log(err.response.data.message);
      if (err.response.data.message === 'EMAIL_ALREADY_REGISTERED') {
        AlertService.error(t("E-mail já cadastrado"));
      } else {
        AlertService.error("Erro ao cadastrar profissional");
      }
      this.setState({ isLoading: false });
    }
  }

  onFiles = async ({ filesUploaded }: any) => {
    var array = filesUploaded.map(function (item: any) {
      return {
        fileName: item.filename,
        url: item.url,
      };
    });
    const policyAndSignature = (await FileStackService.getFileStackSecreteCodeToUser(
      array[0].url, false
    )).data

    this.setState({ fileName: array[0].fileName, files: array, policyAndSignature });
  };

  async componentDidMount(): Promise<void> {
    const idUser = this.props.match.params.id;

    if (idUser) {
      this.setState({ isLoading: true });
      try {
        const { user } = (await UserService.getUserWithOpportunities(idUser)).data;
        const professional: UserData = user;
        this.setState({
            files: professional.attachments ? professional.attachments : [],
            fileName: professional.attachments ? professional?.attachments[0]?.fileName : "",
            arraySkill: (professional.perfilUsuario.digomais.lista.filter((element: any) => {
              return element.classif === "digomais"
            })).map((key: any) =>  key.nome),
            name: professional.firstName,
            email: professional.email,
            lastName: professional.lastName,
            telefone: professional.contato?.lista[0]?.contato,
            linkedinUrl: professional.linkedinUrl ? professional.linkedinUrl : "",
            isPcd: professional.dadosPessoais.pcdUser.isUserPCD,
            europeanUnionCitizenship: professional.europeanUnionCitizenship,
            notification: professional.notificationsSettings.channels[0].isActive,
            userInterests: (professional?.perfilUsuario?.mosaico.escolhidas.filter((element: any) => {
              return element.detalhe.classif === "euquero" || element.detalhe.subclassif === "euquero"
            })).map((key: any) =>  key.detalhe.nome),
            isLoading: false
        })
      } catch (err: any) {
        console.log("Erro ao buscar informação do Profissional", err)
      }
    }

    await this.policeAndSignatureString();
    const domains = await DictionaryService.getInterests();
    this.setState({ arrayInterests: domains.data.euquero });
  }

  render(): React.ReactNode {
    return (
      <div className={styles['main-container']}>
        <Loading show={this.state.isLoading} />
        <Row className={styles['position-content']}>
          <Col sm={5}>
            <label className={styles['style-label']}>
              {t("Nome")}
            </label>
            <Input
              validation={
                this.state.alertMessage &&
                this.state.name.length < 3
                ? "error"
                : undefined
              }
              name="name"
              value={this.state.name}
              onChange={this.handleChange}
              tabIndex={1}
              aria-label="Digite o nome do candidato."
            />
              {
                this.state.alertMessage &&
                this.state.name.length < 3 &&
                <div className={styles['alert-fields']}>
                  <Message  validation="error">
                    {t("Campo obrigatório")}
                  </Message>
                </div>
              }
            <label className={[styles['style-label'], "mt-4"].join(" ")}>
              E-mail
            </label>
            <Input
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              validation={
                this.state.alertMessage &&
                this.state.email.length < 1
                ? "error"
                : undefined
              }
              tabIndex={3}
              aria-label="Digite o e-mail do candidato."
            />
            {
              this.state.alertMessage &&
              this.state.email.length < 3 &&
              <div className={styles['alert-fields']}>
                <Message  validation="error">
                  {t("Campo obrigatório")}
                </Message>
              </div>
            }
            {
              this.state.alertMessage &&
              !this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) &&
              this.state.email.length > 3 &&
              <div className={styles['alert-fields']}>
                <Message  validation="error">
                  {t("Endereço de e-mail inválido")}
                </Message>
              </div>
            }
          </Col>
          <Col sm={5}>
            <label className={styles['style-label']}>
              {t("Sobrenome")}
            </label>
            <Input
              validation={
                this.state.alertMessage &&
                this.state.lastName.length < 3
                ? "error"
                : undefined
              }
              name="lastName"
              value={this.state.lastName}
              onChange={this.handleChange}
              tabIndex={2}
              aria-label="Digite o sobrenome do candidato."
            />
            {
              this.state.alertMessage &&
              this.state.lastName.length < 3 &&
              <div className={styles['alert-fields']}>
                <Message  validation="error">
                  {t("Campo obrigatório")}
                </Message>
              </div>
            }
            <label className={[styles['style-label'], "mt-4"].join(" ")}>
              {t("Telefone")}
            </label>
            <Input
              name="telefone"
              value={this.state.telefone}
              onChange={this.handleChange}
              validation={
                this.state.alertMessage &&
                this.state.telefone.length < 3
                ? "error"
                : undefined
              }
              tabIndex={4}
               aria-label="Digite o telefone do candidato."
            />
            {
              this.state.alertMessage &&
              this.state.telefone.length < 3 &&
              <div className={styles['alert-fields']}>
                <Message  validation="error">
                  {t("Campo obrigatório")}
                </Message>
              </div>
            }
          </Col>
        </Row>
        <Row className={`${styles['position-content']}`}>
          <Col sm={10}>
            <label className={styles['style-label']}>
              {t("Currículo")}
            </label>
            {this.state.policyString.length > 0 && (
              <FilestackReact
                actionOptions={{
                  accept: [".pdf", ".docx"],
                }}
                apikey={process.env.REACT_APP_FILESTACK_API}
                componentDisplayMode={{
                  type: "button",
                  customText: "+",
                  customClass: "c-txt__input",
                }}
                clientOptions={{
                  security: {
                    policy: this.state.policyString,
                    signature: this.state.signatureString,
                  }
                }}
                onSuccess={this.onFiles}
                tabIndex={5}
                aria-label="Insira o currículo do candidato."
              />
            )}
            <div className="d-flex">
              <small  className="pt-1 c-txt__message c-txt__message--success">
                {this.state.fileName}
              </small>
            </div>
            <label className={[styles['style-label'], "mt-4"].join(" ")}>
              {t("Perfil LinkedIn")}
            </label>
            <Input
              value={this.state.linkedinUrl}
              name="linkedinUrl"
              onChange={this.handleChange}
              placeholder="   www.linkedin.com/in/profissional/"
              tabIndex={6}
              aria-label="Insira a url do LinkedIn do candidato."
            />
            <div>
              <label className={[styles['style-label'], "mt-4"].join(" ")}>
                {t("Interesses")}
              </label>
              <div className={styles['field-professional-profile']}>
                <Dropdown
                  onSelect={(value) => this.setState({ userInterests: [...this.state.userInterests, value] })}
                  downshiftProps={{
                    itemToString: (item: DictionaryDomain) => item && item.nome,
                  }}
                >
                  <DropdownField>
                    <Select style={{color: '#C2C8CC'}}>{t("Selecione os interesses do profissional")}</Select>
                  </DropdownField>
                  <Menu>
                    {this.state.arrayInterests.map((option: DictionaryDomain) => (
                      <Item key={option._id} value={option.nome}>
                        {t(option.nome)}
                      </Item>
                    ))}
                  </Menu>
                </Dropdown>
                {this.state.userInterests.map((inter: any, index) => {
                  return (
                    <PillChip
                      key={inter+index.toString()}
                      index={index}
                      chip={inter}
                      onRemove={() => this.removeChip(index, this.state.userInterests, "userInterests")}
                    />
                  )
                })}
              </div>
            </div>
            <label className={[styles['style-label'], "mt-4"].join(" ")}>
              {t("Principais competências")}
            </label >
            <ReactChipInput
              classes={this.state.overlay && this.state.arraySkill.length < 1 ? styles['input-search-skill-overlay'] : styles["input-search-skill"]}
              chips={this.state.arraySkill}
              overlay={this.state.overlay && this.state.arraySkill.length < 1}
              onRemove={(index: number) => this.removeChip(index, this.state.arraySkill, 'arraySkill')}
              onSubmit={(value: string) => this.addChip(value, this.state.arraySkill, 'arraySkill')}
            />
            {
              this.state.alertMessage &&
              this.state.arraySkill.length < 1 &&
              <div className={styles['alert-fields']}>
                <Message  validation="error">
                  {t("Campo obrigatório")}
                </Message>
              </div>
            }
          </Col>
          <Col className="mt-2" sm={10}>
            <Field className="mt-4">
              <Toggle
                checked={this.state.isPcd}
                name="isPcd"
                onChange={this.handleChange}
                tabIndex={7}
              >
                <Label>
                  <p
                    className={styles['style-label']}
                  >
                    {t("Pessoa com Deficiência")}
                  </p>
                </Label>
              </Toggle>
            </Field>
          </Col>
          <Col sm={10}>
            <Field className="mt-4">
              <Toggle
                checked={this.state.notification}
                name="notification"
                onChange={this.handleChange}
                tabIndex={8}
              >
                <Label>
                  <p
                    className={styles['style-label']}
                  >
                    {t("Habilitar envio de E-mail")}
                  </p>
                </Label>
              </Toggle>
            </Field>
          </Col>
          <Col sm={10}>
            <Field className="mt-4">
              <Toggle
                checked={this.state.europeanUnionCitizenship}
                name="europeanUnionCitizenship"
                onChange={this.handleChange}
                tabIndex={9}
              >
                <Label>
                  <p
                    className={styles['style-label']}
                  >
                    {t("Cidadania Europeia")}
                  </p>
                </Label>
              </Toggle>
            </Field>
          </Col>
        </Row>
        <Row className={[
          styles['position-content'],
          styles['position-content-two'],
          'mb-4',
          'p-4'
        ].join(" ")}>
          <Col sm={5}>
            <button
              onClick={() => this.props.history.goBack()}
              className={[styles['color-light'],styles['button-style']].join(" ")}
            >
              {t("Cancelar")}
            </button>
          </Col>
          <Col sm={5}>
            <button
              onClick={this.onSubmit}
              className={[
                styles['color-dark'],
                styles['button-style']
              ].join(" ")}
            >
              {this.props.match.path.endsWith("edit") ? t("Editar") : t("Cadastrar")}
            </button>
          </Col>
        </Row>
      </div>
    )
  }
}

export default ProfessionalByRh;
